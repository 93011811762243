import io from "socket.io-client";

const SOCKET_URL = process.env.VUE_APP_SOCKET_URL;

const socket = io(SOCKET_URL, {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 99999
});

export default () => {
  return socket;
};
